import { SvgIcon } from '../../../../../web/components/svgs/icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

export const DollarSignIcon: React.FC<IProps> = ({ className = '', fill = '#fff' }) => {
	return (
		<SvgIcon className={`dollar-sign-icon ${className}`} height={25} width={13}>
			<path
				d='M7.31543 24.6406V22.2158C8.86426 22.055 10.0682 21.5387 10.9272 20.667C11.7863 19.7952 12.2158 18.6442 12.2158 17.2139C12.2158 16.3167 12.0444 15.5339 11.7017 14.8652C11.3589 14.1966 10.8257 13.6105 10.1021 13.1069C9.37842 12.6034 8.37126 12.1421 7.08057 11.7231C5.78988 11.3042 4.86312 10.8387 4.30029 10.3267C3.73747 9.81462 3.45605 9.12695 3.45605 8.26367C3.45605 7.33268 3.72266 6.60693 4.25586 6.08643C4.78906 5.56592 5.55924 5.30566 6.56641 5.30566C7.53125 5.30566 8.2972 5.6569 8.86426 6.35938C9.43132 7.06185 9.71484 8.00977 9.71484 9.20312V9.20312H12.0508C12.0508 7.4681 11.6551 6.10124 10.8638 5.10254C10.0724 4.10384 8.96582 3.50716 7.54395 3.3125V3.3125V0.519531H5.65234V3.2998C4.23893 3.46908 3.12809 3.99805 2.31982 4.88672C1.51156 5.77539 1.10742 6.91374 1.10742 8.30176C1.10742 9.66439 1.52002 10.7879 2.34521 11.6724C3.17041 12.5568 4.48438 13.2741 6.28711 13.8242C7.58203 14.2559 8.50244 14.7383 9.04834 15.2715C9.59424 15.8047 9.86719 16.4606 9.86719 17.2393C9.86719 18.1618 9.5498 18.8896 8.91504 19.4229C8.28027 19.9561 7.40853 20.2227 6.2998 20.2227C5.16569 20.2227 4.28971 19.9053 3.67188 19.2705C3.05404 18.6357 2.74512 17.7301 2.74512 16.5537V16.5537H0.396484C0.396484 18.2295 0.842936 19.5562 1.73584 20.5337C2.62874 21.5112 3.8623 22.0719 5.43652 22.2158V22.2158V24.6406H7.31543Z'
				fill={fill}
			/>
		</SvgIcon>
	);
};
