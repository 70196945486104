import { styleSheet } from './styles';
import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';

interface IProps {
	className?: string;
	content: React.ReactNode;
	graphic: React.ReactNode;
	styles?: StyleDeclarationValue[];
	title?: string;
}

export class DidYouKnowSection extends React.Component<IProps> {
	public render() {
		const { className, styles, graphic, content, title } = this.props;
		return (
			<div className={`${css(styleSheet.container, ...(styles || []))} did-you-know-section ${className || ''}`}>
				{graphic}
				<div className={css(styleSheet.text, !title ? styleSheet.alignCenter : null)}>
					{title && (
						<>
							<span className={css(styleSheet.bold)}>{title}</span>
							<br />
							<br />
						</>
					)}
					{content}
				</div>
			</div>
		);
	}
}
