import { BreakPoints } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	bold: {
		fontWeight: 'bold',
	},
	container: {},
	didYaKnowContainer: {
		alignItems: 'center',
		background: '#F9F9F9',
		boxSizing: 'border-box',
		display: 'flex',
		height: 200,
		justifyContent: 'space-around',
		margin: 'auto 0 0',
		padding: '20px 20px',
		width: '100%',
	},
	fullscreenDidYaKnowContainer: {
		margin: 'auto 0 -30px',
		width: '100vw',
	},
	overlay: {
		alignItems: 'center',
		background: '#fff',
		display: 'flex',
		flexDirection: 'column',
	},
	overlayCloseButton: {
		marginTop: 20,
	},
	overlayCompleteMessage: {
		marginTop: 60,
		[BreakPoints.tabletCompactHeight]: {
			marginTop: 0,
		},
	},
});
