import { Button } from '../../Button';
import { ISelectOption, Select } from '../../Select';
import { TextingGraphic } from '../../svgs/graphics/TextingGraphic';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { useRef, useState } from 'react';
import * as React from 'react';

interface IProps {
	ctaLabel?: string;
	exclusions?: Api.IPhoneNumber[];
	onPhoneNumberSelected: (phoneNumber: Api.IPhoneNumber) => void;
	recipient: Api.TextRecipientViewModel;
}

const getRecipientPhoneOptions = (
	recipient: Api.TextRecipientViewModel,
	exclusions: Api.IPhoneNumber[] = []
): ISelectOption<Api.IPhoneNumber>[] => {
	return Api.VmUtils.getTextMessageCapablePhoneNumbers(recipient?.phoneNumbers)
		.filter(p => {
			let include = true;
			exclusions.forEach(e => {
				if (p.metadata?.standard === e.metadata?.standard) {
					include = false;
				}
			});
			return include;
		})
		.map((p, i) => {
			return {
				dataContext: p,
				id: p.metadata?.e164?.replace('+', '') || `phoneOption${i}`,
				text: `${p.label}: ${p.metadata?.standard || p.value}`,
			};
		});
};

const getDefaultPhone = (recipient: Api.TextRecipientViewModel) => {
	if (!recipient.phoneNumbers?.length) {
		return null;
	}

	return Api.VmUtils.getTextMessageCapablePhoneNumbers(recipient.phoneNumbers)[0] || recipient.phoneNumbers[0];
};

export const MultiPhoneSelect: React.FC<IProps> = ({
	ctaLabel = 'Use this number',
	exclusions = [],
	onPhoneNumberSelected,
	recipient,
}) => {
	const options = useRef(getRecipientPhoneOptions(recipient, exclusions)).current;
	const defaultPhone = getDefaultPhone(recipient);
	const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<ISelectOption<Api.IPhoneNumber>>(
		// @ts-ignore
		// @ts-ignore
		options.find(o => o.dataContext.metadata?.e164 === defaultPhone.metadata?.e164)
	);

	const onMultipleNumberSelect = (option: ISelectOption<Api.IPhoneNumber>, wasSelected: boolean) => {
		if (wasSelected) {
			setSelectedPhoneNumber(option);
		}
	};

	const onUseThisNumberClick = () => {
		onPhoneNumberSelected(selectedPhoneNumber.dataContext);
	};

	if (!options?.length) {
		// no viable options to choose from
		// Possible: "exclusions" list contains all od "recipient.phoneNumbers", or "recipient.phoneNumbers"
		return (
			<div className={css(styleSheet.selectContainer, styleSheet.error)}>
				There are no valid phone number options for this recipient.
			</div>
		);
	}

	return (
		<div className={css(styleSheet.selectContainer)}>
			<Select
				maxOptionsToShow={5}
				onOptionClick={onMultipleNumberSelect}
				options={options}
				selectedOption={selectedPhoneNumber}
				styles={[styleSheet.multiplePhoneNumbersSelect]}
				triggerStyles={[styleSheet.multiplePhoneNumbersSelectTitle]}
			/>
			{!selectedPhoneNumber?.dataContext?.metadata?.standard && (
				<p className={css(styleSheet.error)}>This number is invalid. Please edit it before continuing.</p>
			)}

			<Button
				className={css(styleSheet.useThisNumberCta)}
				label={ctaLabel}
				onClick={onUseThisNumberClick}
				disabled={!selectedPhoneNumber?.dataContext.metadata?.standard}
			/>
		</div>
	);
};

export const NoConversationMultiPhoneSelect: React.FC<IProps> = props => {
	return (
		<div className={css(styleSheet.multiplePhoneNumbersSelectContainer)}>
			<div className={css(styleSheet.textingGraphicContainer)}>
				<TextingGraphic animate={true} />
			</div>
			<div className={css(styleSheet.header)}>No text conversation started</div>
			<div className={css(styleSheet.gray)}>This contact has multiple phone numbers.</div>
			<div className={css(styleSheet.gray)}>Text with:</div>
			<MultiPhoneSelect {...props} />
		</div>
	);
};
