import { mention, navigation, titles } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const BodyInputsWidth = '50vw';
const MinBodyInputsWidth = 450;
const MaxBodyInputsWidth = 800;

export const styleSheet = StyleSheet.create({
	body: {
		'@media only screen and (min-height: 1024px)': {
			height: 660,
			maxHeight: 660,
			minHeight: 660,
		},
		'@media only screen and (min-height: 768px)': {
			height: 460,
			maxHeight: 460,
			minHeight: 460,
		},
		display: 'flex',
		flexGrow: 1,
		height: 420,
		marginTop: 16,
	},
	bodyContext: {
		'@media only screen and (max-width: 1200px)': {
			width: 360,
		},
		display: 'flex',
		marginLeft: 30,
		maxHeight: '100%',
		width: 460,
	},
	bodyContextTabView: {
		':nth-child(1n).tab-view': {
			flexGrow: 1,
			width: '100%',
		},
	},
	bodyEditor: {
		flexBasis: 0,
		flexGrow: 1,
		[BrowserMediaQueries.IE11]: {
			// IE11 BS... no position: sticky
			...CssSelectors.allDescendants(
				{
					maxWidth: `${MaxBodyInputsWidth - 2}px`,
					minWidth: MinBodyInputsWidth,
				},
				'.tox.tox-tinymce .tox-editor-container .tox-editor-header'
			),
		},
	},
	bodyInputs: {
		display: 'flex',
		flexDirection: 'column',
		flexShrink: 0,
		maxHeight: '100%',
		maxWidth: MaxBodyInputsWidth,
		minWidth: MinBodyInputsWidth,
		position: 'relative',
		width: BodyInputsWidth,
		...CssSelectors.allDescendants(
			{
				fontSize: 14,
			},
			'input'
		),
		...CssSelectors.allChildren(
			{
				marginTop: 10,
			},
			':not(:first-child)'
		),
	},
	composerHidden: {
		opacity: 0,
	},
	container: {
		position: 'relative',
	},
	dropdownAnchor: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		padding: 10,
	},
	dropdownAnchorText: {
		maxWidth: 600,
	},
	dropdownIcon: {
		height: 16,
		marginLeft: 12,
		transform: 'rotate(90deg)',
		width: 10,
	},
	dropdownInline: {
		borderColor: '#CCCCCC',
		borderRadius: 3,
		borderStyle: 'solid',
		borderWidth: 1,
		boxSizing: 'border-box',
		maxWidth: 600,
		overflowY: 'visible',
		width: '100%',
	},
	dropdownItem: {
		':hover': {
			background: mention,
		},
		cursor: 'pointer',
		padding: 10,
	},
	dropdownMenu: {
		background: '#fff',
		border: '1px solid #E2E2E2',
		boxShadow: '0 0 6px 0 rgba(184,184,184,0.30)',
		marginTop: 21,
		maxHeight: 300,
		overflowY: 'auto',
		width: '100%',
	},
	footer: {
		boxSizing: 'border-box',
		display: 'flex',
		paddingTop: 30,
		...CssSelectors.allChildren(
			{
				marginLeft: 10,
			},
			':not(:first-child)'
		),
	},
	header: {
		color: titles,
		fontSize: 18,
		lineHeight: '22px',
		maxWidth: MaxBodyInputsWidth,
		minWidth: MinBodyInputsWidth,
		width: BodyInputsWidth,
	},
	headerName: {
		maxWidth: '60%',
	},
	overlay: {
		height: '100%',
		left: 0,
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 1,
	},
	popoverBody: {
		color: navigation,
		fontSize: 14,
	},
	popoverButtons: {
		...CssSelectors.allChildren(
			{
				paddingLeft: 15,
			},
			':not(:first-child)'
		),
	},
	popoverContent: {
		maxWidth: 380,
		padding: 15,
		...CssSelectors.allChildren(
			{
				paddingBottom: 15,
			},
			':not(:last-child)'
		),
	},
	popoverHeader: {
		color: titles,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
	},
	singleRecipientToField: {
		...CssSelectors.allDescendants(
			{
				zIndex: 2,
			},
			'.dropdown-content'
		),
	},
});
