import { CSSProperties, StyleSheet } from 'aphrodite';
import { brandPrimary, destructive, grayIconFill, header, navigation, titles } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors } from '../../../styles/styles';

const status: CSSProperties = {
	fontSize: '12px',
	margin: '5px 0 0',
};

export const styleSheet = StyleSheet.create({
	avatar: {
		height: 36,
		minHeight: 36,
		minWidth: 36,
		width: 36,
		...CssSelectors.allChildren(
			{
				fontWeight: 400,
			},
			'.avatar-content'
		),
	},
	bubble: {
		borderRadius: 20,
		display: 'inline-block',
		fontSize: '14px',
		lineHeight: '1.6em',
		marginRight: 2,
		padding: '10px 20px',
		position: 'relative',
		whiteSpace: 'pre-wrap',
		width: 'auto',
		wordBreak: 'break-word',
		[BrowserMediaQueries.IE11]: {
			wordBreak: 'break-all',
		},
	},
	bubbleReceived: {
		background: '#ebeeef',
		color: titles,
		marginBottom: 8,
	},
	bubbleSent: {
		background: brandPrimary,
		color: '#fff',
	},
	bubbleSentWrapper: {
		justifyContent: 'flex-end',
	},
	bubbleWrapper: {
		alignItems: 'center',
		maxWidth: '80%',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 6,
		width: '100%',
		[BrowserMediaQueries.IE11]: {
			transform: 'rotate(180deg)',
		},
	},
	containerReceived: {
		alignItems: 'flex-start',
	},
	containerSent: {
		alignItems: 'flex-end',
	},
	containerWithoutBubble: {
		display: 'flex',
		flexDirection: 'column',
		marginBottom: 6,
		width: '100%',
	},
	downloadImageBtn: {
		alignItems: 'center',
		display: 'flex',
		marginTop: 8,
		...CssSelectors.allChildren(
			{
				marginLeft: 8,
				width: 15,
			},
			'svg'
		),
	},
	error: {
		...status,
		color: destructive,
	},
	errorIcon: {
		left: 'calc(100% + 2px)',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
	},
	file: {
		':hover': {
			cursor: 'pointer',
		},
		background: '#fff',
		borderRadius: 5,
		padding: '10px',
	},
	fileDownload: {},
	fileIcon: {
		alignItems: 'center',
		color: navigation,
		display: 'flex',
		flexDirection: 'column',
		fontSize: '11px',
		justifyContent: 'center',
		lineHeight: '1em',
		padding: '0 10px',
		...CssSelectors.allChildren(
			{
				height: 28,
				width: 30,
			},
			'svg'
		),
	},
	fileInfo: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 200,
		...CssSelectors.allChildren(
			{
				color: '#000',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				whiteSpace: 'nowrap',
			},
			':first-child'
		),
		...CssSelectors.allChildren(
			{
				color: navigation,
				fontSize: '14px',
			},
			':last-child'
		),
	},
	image: {
		border: '2px solid transparent',
		borderRadius: 8,
		marginTop: 5,
		maxWidth: '100%',
		transition: 'border .25s ease-in-out',
	},
	imageDownloadContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	imageFull: {
		display: 'block',
		maxWidth: 600,
	},
	imageReceived: {
		':hover': {
			border: `2px solid ${brandPrimary}`,
			cursor: 'pointer',
		},
	},
	imageSent: {
		':hover': {
			border: '2px solid #fff',
			cursor: 'pointer',
		},
	},
	noBubble: {
		fontSize: 35,
		padding: '0px',
	},
	status: {
		...status,
		color: header,
	},
	tail: {
		bottom: 0,
		position: 'absolute',
	},
	tailReceived: {
		left: -7,
		transform: 'rotateY(180deg)',
	},
	tailSent: {
		right: -7,
	},
	timestamp: {
		color: grayIconFill,
		fontSize: 12,
		margin: '2px 14px',
	},
});
