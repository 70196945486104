import { useEffect, useState } from 'react';

export const useUpdateCount = (count = 0, speed = 50) => {
	const [updateCount, setUpdateCount] = useState(0);
	useEffect(() => {
		if (updateCount < count) {
			const interval = setInterval(() => {
				setUpdateCount(updateCount + 1);
			}, speed);
			return () => clearInterval(interval);
		}
	}, [updateCount, count, speed]);
	return updateCount;
};
