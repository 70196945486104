import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import momentTz from 'moment-timezone';
import * as React from 'react';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import {
	EmailWorkloadViewModel,
	IContactFilterCriteria,
	IEmailSendEstimate,
	IOperationResultNoValue,
	IScheduledSend,
	KeyDateKind,
	ScheduleCriteria,
} from '../../../../viewmodels/AppViewModels';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { SendNowIcon } from '../../svgs/icons/SendNowIcon';
import { ConfigureScheduledSend } from '../ConfigureScheduledSend';
import { KeyDateSendOptions } from '../KeyDateSendOptions';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps, IUserSessionComponentProps {
	className?: string;
	estimate?: IEmailSendEstimate;
	estimatedEmailTotal: number;
	header?: React.ReactNode;
	hideScheduler?: boolean;
	hideSendNow?: boolean;
	keyDateType?: IContactFilterCriteria;
	onSend?(schedule: IScheduledSend): Promise<any> | null | undefined;
}

interface IState {
	isSending?: boolean;
	selectedSendDate?: Date;
	sendEstimateForToday?: IEmailSendEstimate;
}

class _SendScheduler extends React.Component<IProps, IState> {
	public readonly state: IState = {};
	private mEmailWorkload: EmailWorkloadViewModel;
	// @ts-ignore
	private mMounted: boolean;

	constructor(props: IProps) {
		super(props);
		// @ts-ignore
		this.mEmailWorkload = new EmailWorkloadViewModel(props.userSession);
	}

	public componentDidMount() {
		this.mMounted = true;
		this.loadEstimateForSendNow();
	}

	public componentWillUnmount() {
		this.mMounted = false;
	}

	public render() {
		const { className, header, estimatedEmailTotal, hideScheduler } = this.props;
		const { sendEstimateForToday, selectedSendDate } = this.state;
		return (
			<div className={`${css(styleSheet.container)} send-scheduler ${className || ''}`}>
				{header === false
					? null
					: header || (
							<div className={css(styleSheet.header)}>
								<span>Send Options</span>
							</div>
						)}
				<div className={css(styleSheet.body)}>
					{this.renderBirthday()}
					{this.renderSendNowOption()}
					{!hideScheduler && (
						<ConfigureScheduledSend
							className={css(styleSheet.configureSend)}
							estimate={sendEstimateForToday}
							numberOfEmailsToSend={estimatedEmailTotal}
							onDone={this.onSend(ScheduleCriteria.StartAfter, selectedSendDate)}
							onSelectedDateChanged={this.onSelectedSendDateChanged}
							selectedDate={selectedSendDate}
						/>
					)}
				</div>
			</div>
		);
	}

	private renderBirthday() {
		const { keyDateType } = this.props;
		const { isSending } = this.state;

		if (!keyDateType) {
			return null;
		}

		return (
			<KeyDateSendOptions
				isSending={isSending}
				keyDateKind={keyDateType.value as KeyDateKind}
				onScheduleClicked={this.onSendOnBirthdays}
				styles={[styleSheet.sendNow]}
			/>
		);
	}

	private onSendOnBirthdays = (criteria: ScheduleCriteria, date?: Date) => {
		this.onSend(criteria, date)();
	};

	private renderSendNowOption() {
		const { estimatedEmailTotal, hideSendNow } = this.props;
		const { sendEstimateForToday, isSending } = this.state;
		const numberOfDaysToFinish = this.numberOfDaysToFinishIfSentNow;
		const dailyEmailLimit = this.dailySendLimit;

		if (hideSendNow) {
			return null;
		}

		return (
			<div className={css(styleSheet.sendNow)}>
				<SendNowIcon />
				<div className={css(styleSheet.sendNowBody)}>
					{sendEstimateForToday ? (
						<>
							<div className={css(styleSheet.sendNowTitle)}>Send Now</div>
							<div
								className={css(
									styleSheet.sendNowMessage,
									numberOfDaysToFinish !== 0 ? styleSheet.sendNowMessageWarning : null
								)}
							>
								{numberOfDaysToFinish === 0 ? (
									`We will send ${
										estimatedEmailTotal > 1 ? `all emails` : 'your email'
									} right away. This may take a few minutes, and we will email you once the send is complete.`
								) : (
									<>
										{!!dailyEmailLimit && (
											<div>{`Please note that your email server is limiting your daily send to ${dailyEmailLimit.count} per day.`}</div>
										)}
										<div style={{ marginTop: 8 }}>
											Levitate will send the emails over the course of the next
											<span style={{ fontWeight: 700 }}>
												&nbsp;
												{`${numberOfDaysToFinish} days`}
											</span>
											.
										</div>
									</>
								)}
							</div>
						</>
					) : (
						<LoadingSpinner type='large' />
					)}
					<button
						className={css(baseStyleSheet.ctaButton, styleSheet.sendNowButton)}
						disabled={!sendEstimateForToday || !!isSending}
						onClick={this.onSend(ScheduleCriteria.Immediately)}
					>
						<span>{`${numberOfDaysToFinish <= 0 ? 'Send Now' : 'Start Sending'}`}</span>
					</button>
				</div>
			</div>
		);
	}

	private get dailySendLimit() {
		const { sendEstimateForToday } = this.state;
		return sendEstimateForToday
			? sendEstimateForToday.emailWorkload.limits.find(x => x.intervalInMinutes === 1440)
			: null;
	}

	private get numberOfDaysToFinishIfSentNow() {
		const { sendEstimateForToday } = this.state;
		const estimatedEndDate = sendEstimateForToday ? moment(sendEstimateForToday.estimate.endDate) : null;
		const numberOfDaysToFinish = estimatedEndDate ? estimatedEndDate.diff(moment(), 'days') : -1;
		return numberOfDaysToFinish;
	}

	private onSelectedSendDateChanged = (selectedSendDate: Date) => {
		this.setState({
			selectedSendDate,
		});
	};

	private onSend = (criteria: ScheduleCriteria, startDate?: Date) => () => {
		const { onSend, estimate } = this.props;
		const dateStringValue = moment.utc(startDate).toISOString();
		if (onSend) {
			const schedule: IScheduledSend = {
				criteria,
				expirationDate: estimate?.estimate?.expirationDate,
				// @ts-ignore
				startDate: startDate ? dateStringValue : null,
			};
			const promise = onSend(schedule);
			if (promise) {
				this.setState({
					isSending: true,
				});
				const onFinish = () => {
					if (this.mMounted) {
						this.setState({
							isSending: false,
						});
					}
				};
				promise.then(onFinish).catch(onFinish);
			}
			return promise;
		}
	};

	private loadEstimateForSendNow = () => {
		const { estimatedEmailTotal, estimate, logApiError, logEvent } = this.props;
		const sendScheduleForToday: IScheduledSend = {
			criteria: ScheduleCriteria.Immediately,
			expirationDate: estimate?.estimate?.expirationDate,
			startDate: new Date().toISOString(),
		};
		// @ts-ignore
		logEvent('GetEstimateForSendNow');
		const promise = this.mEmailWorkload
			.getScheduledSendEstimate(sendScheduleForToday, estimatedEmailTotal, momentTz.tz.guess())
			.then(opResult => {
				this.setState({
					sendEstimateForToday: opResult.value,
				});
			})
			.catch((error: IOperationResultNoValue) => {
				// @ts-ignore
				logApiError('GetEstimateForSendNow-Error', error);
			});
		return promise;
	};
}

const SendSchedulerAsObserver = observer(_SendScheduler);
const SendSchedulerWithContext = inject(UserSessionViewModelKey)(SendSchedulerAsObserver);
export const SendScheduler = withEventLogging(SendSchedulerWithContext, 'SendScheduler');

export interface ISendSchedulerOverlayProps extends IProps {
	estimate?: IEmailSendEstimate;
	hideBackButton?: boolean;
	hideHeader?: boolean;
	hideSendNow?: boolean;
	isBusy?: boolean;
	onBackButtonClicked?(): void;
	styles?: StyleDeclarationValue | StyleDeclarationValue[];
}

export const SendSchedulerOverlay: React.FC<ISendSchedulerOverlayProps> = props => {
	const { styles, isBusy, hideHeader, hideSendNow, onBackButtonClicked, hideBackButton, estimate, ...restProps } =
		props;
	const computedStyles = Array.isArray(styles) ? styles : styles ? [styles] : [];
	return (
		<div className={`send-scheduler-overlay ${css(styleSheet.overlay, ...computedStyles)}`}>
			{!hideBackButton && (
				<button
					className={css(baseStyleSheet.brandSecondaryLink, styleSheet.overlayBackButton)}
					disabled={!!isBusy}
					onClick={onBackButtonClicked}
				>
					<span>{'< Back to Email'}</span>
				</button>
			)}
			<SendScheduler
				{...restProps}
				estimate={estimate}
				header={hideHeader ? true : undefined}
				hideSendNow={hideSendNow}
			/>
		</div>
	);
};
