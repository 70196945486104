import { StyleSheet } from 'aphrodite';
import { inputBorderColor, titles } from '../../../styles/colors';
import { textFieldPadding } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	emailPreview: {
		display: 'flex',
		flexDirection: 'column',
		width: 600,
	},
	emailPreviewBody: {
		display: 'flex',
		flexGrow: 1,
		height: 275,
		marginTop: '0.5rem',
		position: 'relative',
	},
	emailPreviewBodyIframe: {
		backgroundColor: 'white',
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 3,
		boxSizing: 'border-box',
		color: titles,
		flexGrow: 1,
		fontSize: '0.875rem',
		height: '100%',
		padding: textFieldPadding,
	},
	emailPreviewFooter: {
		marginTop: '0.5rem',
	},
	emailPreviewHeader: {
		textAlign: 'right',
	},
	emailPreviewTitleHeader: {
		color: titles,
		fontSize: '18px',
		lineHeight: '22px',
	},
});
