import { StyleDeclarationValue, css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { IUserSessionComponentProps, UserSessionViewModelKey } from '../../../../models/AppState';
import { baseStyleSheet } from '../../../styles/styles';
import { DidYouKnowSection } from '../../reporting/emails/DidYouKnowSection';
import { BouncedEmailsGraphic } from '../../svgs/graphics/BouncedEmailsGraphic';
import { UnsubscribeEmailGraphic } from '../../svgs/graphics/UnsubscribeEmailGraphic';
import { ISendSchedulerCompleteProps, SendSchedulerComplete } from '../SendSchedulerComplete';
import { styleSheet } from './styles';

interface ISendSchedulerCompleteOverlayProps
	extends ISendSchedulerCompleteProps,
		IUserSessionComponentProps,
		IModalContext,
		Partial<RouteComponentProps<any>> {
	onCloseButtonClicked?(e: React.MouseEvent<HTMLElement>): void;
	styles?: StyleDeclarationValue[];
}

class _SendSchedulerCompleteOverlay extends React.Component<ISendSchedulerCompleteOverlayProps> {
	public render() {
		const { styles, onCloseButtonClicked, userSession, parentModal, ...restProps } = this.props;
		return (
			<div className={css(styleSheet.overlay, ...(styles || []))}>
				<SendSchedulerComplete {...restProps} className={css(styleSheet.overlayCompleteMessage)} />
				<button className={css(baseStyleSheet.ctaButton, styleSheet.overlayCloseButton)} onClick={onCloseButtonClicked}>
					Close Window
				</button>
				<div
					className={css(
						styleSheet.didYaKnowContainer,
						parentModal?.name === 'fullscreenModal' ? styleSheet.fullscreenDidYaKnowContainer : null
					)}
				>
					<DidYouKnowSection
						content={this.renderBounceContent()}
						graphic={<BouncedEmailsGraphic />}
						title='Did you know?'
					/>
					<DidYouKnowSection
						content={this.renderUnsubscribeContent()}
						graphic={<UnsubscribeEmailGraphic />}
						title='Did you know?'
					/>
				</div>
			</div>
		);
	}

	private renderBounceContent() {
		// @ts-ignore
		// @ts-ignore
		const provider = this.props.userSession.account.emailProviderConfiguration[0].emailProvider;
		if (provider === 'Gmail' || provider === 'Google' || provider === 'Office365') {
			return (
				<div>
					Some bounced emails are expected. Levitate will automatically tag the bad email addresses for you with
					&quot;Email Bounced&quot;.
				</div>
			);
		}
		return (
			<div>
				Some bounced emails are expected. Please
				<span className={css(styleSheet.bold)}> leave those bounced emails in your inbox for 12 hours, </span>
				and Levitate will automatically tag the bad email addresses for you with &quot;Email Bounced&quot;.
			</div>
		);
	}

	private renderUnsubscribeContent() {
		return (
			<div>
				If someone wishes to stop receiving future emails, look for the
				<span className={css(styleSheet.bold)}> “Unsubscribe this contact from my group emails” </span>
				option under their profile.
			</div>
		);
	}
}

const SendSchedulerCompleteOverlayAsObserver = observer(_SendSchedulerCompleteOverlay);
export const SendSchedulerCompleteOverlay = inject(
	UserSessionViewModelKey,
	ModalChildComponentContextKey
)(SendSchedulerCompleteOverlayAsObserver);
