import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';
import { DollarSignIcon } from '../../../../aida/components/svgs/icons/DollarSignIcon';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../models/UiUtils';
import { brandPrimary, brandSecondary, noteTintColor, success, warningDark } from '../../../styles/colors';
import { baseStyleSheet } from '../../../styles/styles';
import { Attachments } from '../../Attachments';
import { DeprecatedMoreMenu } from '../../DeprecatedMoreMenu';
import { IMoreMenuItem } from '../../MoreMenu';
import { Popover, PopoverType } from '../../Popover';
import { DefaultCompactEditorConfig } from '../../notes/NoteEditor';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { ActionItemsIcon } from '../../svgs/icons/ActionItemsIcon';
import { CalendarDateIcon } from '../../svgs/icons/CalendarDateIcon';
import { CallIcon } from '../../svgs/icons/CallIcon';
import { CheckmarkIcon } from '../../svgs/icons/CheckmarkIcon';
import { ClockIcon } from '../../svgs/icons/ClockIcon';
import { EmailIcon } from '../../svgs/icons/EmailIcon';
import { NotesIcon } from '../../svgs/icons/NotesIcon';
import { PostageIcon } from '../../svgs/icons/PostageIcon';
import { QuestionMarkIcon } from '../../svgs/icons/QuestionMarkIcon';
import { StarIcon } from '../../svgs/icons/StarIcon';
import { TextingIcon } from '../../svgs/icons/TextingIcon';
import { styleSheet } from './styles';

export interface IEntityTimelineEventBaseProps<
	TTimelineEvent extends Api.ITimelineEvent = Api.ITimelineEvent,
	T extends Api.TimelineEventViewModel<TTimelineEvent> = Api.TimelineEventViewModel<TTimelineEvent>,
> {
	event: T;
}

export const EntityTimelineEventIcon: React.FC<IEntityTimelineEventBaseProps> = ({ event }) => {
	switch (event.type) {
		case 'SentEmailEvent':
		case 'ReceivedEmailEvent':
		case 'ViewedEmailEvent':
		case 'RepliedEmailEvent':
		case 'ExternalSentEmailEvent':
		case 'HtmlNewsletterEvent':
			return <EmailIcon fillColor={brandPrimary} />;
		case 'MeetingEvent':
		case 'MeetingScheduledEvent':
			return <CalendarDateIcon fillColor={brandSecondary} />;
		case 'DealCreatedEvent':
		case 'DealUpdatedEvent':
			return <DollarSignIcon className={css(styleSheet.dollarSignIcon)} fill={brandSecondary} />;
		case 'NoteEvent':
		case 'SkipLeadEvent':
			return <NotesIcon fillColor={noteTintColor} />;
		case 'ActionItemEvent':
			return <ActionItemsIcon fill={success} />;
		case 'ConversationThreadEvent':
			return <TextingIcon fill={warningDark} />;
		case 'PhoneCallEvent':
		case 'PhoneCallCompletedEvent':
		case 'UntrackedPhoneCallEvent':
			return <CallIcon fillColor={success} />;
		case 'FollowUpEvent':
		case 'CancelledFollowUpEvent':
		case 'RescheduledFollowUpEvent':
			return <ClockIcon fillColor={brandSecondary} />;
		case 'SatisfactionSurveyResponseEvent':
			return (
				<StarIcon fillColor='#F3CE0D' filled={true} className={css(styleSheet.satisfactionSurveyResponseEventIcon)} />
			);
		case 'HandwrittenCardOrderEvent':
			return <PostageIcon fillColor={brandPrimary} />;
		default:
			return null;
	}
};

export const EntityTimelineEventDateHeader: React.FC<{ date: Date }> = ({ date }) => {
	return (
		<div className={css(styleSheet.eventDateContainer)}>
			<div className={css(styleSheet.eventDate)}>{moment(date).format('dddd, MMMM Do, YYYY')}</div>
			<div className={css(styleSheet.eventDateFillLine)} />
		</div>
	);
};

export interface IEntityTimelineEventActionProps extends IEntityTimelineEventBaseProps {
	onMoreMenuItemClicked?(
		richContent: Api.RichContentViewModel,
		menuItem: IMoreMenuItem,
		e: React.MouseEvent<HTMLElement>
	): void;
	readonly?: boolean;
}

export const EntityTimelineEventAction: React.FC<IEntityTimelineEventActionProps> = observer(
	// @ts-ignore
	({ event, readonly, onMoreMenuItemClicked }) => {
		const SHOW_POPOVER_FLAG = false;
		const [toggleShowingTooltip, setToggleShowingTooltip] = React.useState(false);
		const baseMenuItems = React.useRef<IMoreMenuItem<string>[]>([
			{
				name: 'Edit',
				representedObject: 'edit',
			},
			{
				name: 'Delete',
				representedObject: 'delete',
			},
		]).current;

		const menuItemClicked =
			(richContent: Api.RichContentViewModel) => (menuItem: IMoreMenuItem, e: React.MouseEvent<HTMLElement>) => {
				onMoreMenuItemClicked?.(richContent, menuItem, e);
			};

		const renderMenuOptions = (menuEvent: Api.NoteEventViewModel) => {
			// @ts-ignore
			const richContent: Api.RichContentViewModel = (menuEvent as Api.NoteEventViewModel).viewmodel;
			if (!readonly) {
				// config menu items
				let menuItems = [...baseMenuItems];
				if (!richContent.canEdit) {
					menuItems = menuItems.filter(x => x.representedObject !== 'edit');
				}

				if (!richContent.canDelete) {
					menuItems = menuItems.filter(x => x.representedObject !== 'delete');
				}

				if (menuItems.length > 0) {
					return (
						<DeprecatedMoreMenu
							menuButtonClassName={`${
								richContent?.context?.source === Api.RichContentContextSource.EmailMessageSend &&
								css(styleSheet.moreMenu)
							} entity-notes-list-header-more-menu`}
							menuItems={menuItems}
							onMenuItemClicked={menuItemClicked(richContent)}
						/>
					);
				}
			}
		};

		if (event.type === 'SentEmailEvent' || event.type === 'HtmlNewsletterEvent') {
			if (!(event as Api.SentEmailEventViewModel)?.viewmodel?.rawContentState) {
				return <span className={css(styleSheet.sentOutSideOfLev)}>Sent outside of Levitate</span>;
			}

			// render email viewed
			const openDate = (event as Api.SentEmailEventViewModel)?.openDate;
			return openDate ? (
				<>
					<div className={css(baseStyleSheet.flex)}>
						<div className={css(styleSheet.viewedOn)}>
							<span>
								<CheckmarkIcon className={css(baseStyleSheet.absoluteCenter)} fillColor='#fff' />
							</span>
							Viewed on {moment(openDate).format('MM/DD/YYYY')}
						</div>
						{renderMenuOptions(event as Api.NoteEventViewModel)}
					</div>
					{SHOW_POPOVER_FLAG ? (
						<div>
							<Popover
								className={css(styleSheet.popOver)}
								anchor={
									<div
										className={css(styleSheet.popOverIconWrap)}
										onMouseEnter={() => setToggleShowingTooltip(true)}
										onMouseLeave={() => setToggleShowingTooltip(false)}
									>
										<QuestionMarkIcon className={css(styleSheet.questionMarkIcon)} fillColor={brandPrimary} /> Viewed on
										<p>Apple Device</p>
									</div>
								}
								contentClassName={css(styleSheet.popOverContentWrap)}
								dismissOnClickOutside={false}
								isOpen={toggleShowingTooltip}
								place='below'
								preferredPlacement='right'
								tipSize={0.01}
								type={PopoverType.gray}
							>
								<p className={css(styleSheet.popOverContent)}>
									This email was opened by an Apple device, we can&rsquo;t <br />
									determine if this contact has viewed the email.
								</p>
							</Popover>
						</div>
					) : null}
				</>
			) : (
				<div className={css(baseStyleSheet.flex)}>
					<div className={css(styleSheet.viewedOn)}>Email not viewed</div>
					{renderMenuOptions(event as Api.NoteEventViewModel)}
				</div>
			);
		} else if (event.type === 'NoteEvent' || event.type === 'ActionItemEvent' || event.type === 'PhoneCallEvent') {
			if (!(event as Api.NoteEventViewModel)?.viewmodel?.rawContentState) {
				return null;
			}
			return renderMenuOptions(event as Api.NoteEventViewModel);
		}

		return null;
	}
);

export const EntityTimelineEventTitle: React.FC<IEntityTimelineEventBaseProps> = ({ event }) => {
	let decoratedTitle: React.ReactNode = null;
	if (event instanceof Api.SatisfactionSurveyResponseEventViewModel) {
		// @ts-ignore
		const surveyNameIndex = new RegExp(`${event.surveyName}`, 'igm').exec(event.title)?.index || -1;
		if (surveyNameIndex >= 0) {
			// @ts-ignore
			const ratingIndex = new RegExp(`${event.response.rating}$`, 'igm').exec(event.title)?.index || -1;
			// @ts-ignore
			let remainder = event.title.substring(surveyNameIndex + event.surveyName.length);
			if (ratingIndex >= 0) {
				remainder = remainder.substring(0, remainder.length - event.response.rating.toString().length);
			}
			decoratedTitle = (
				<>
					{/* @ts-ignore */}
					<span>{event.title.substring(0, surveyNameIndex)}</span>
					&nbsp;
					<span className={css(baseStyleSheet.truncateText, styleSheet.eventTitleSurveyName)} title={event.surveyName}>
						{`"${event.surveyName}"`}
					</span>
					&nbsp;
					{ratingIndex >= 0 ? (
						<span>
							{remainder}
							<span className={css(styleSheet.eventTitleSurveyRating)}>{event.response.rating}</span>
						</span>
					) : (
						<span>{remainder}</span>
					)}
				</>
			);
		}
	}
	return (
		<div className={`entity-title ${css(baseStyleSheet.truncateText, styleSheet.eventTitle)}`} title={event.title}>
			{decoratedTitle || <div className={css(baseStyleSheet.truncateText)}>{event.title}</div>}
		</div>
	);
};

export const EntityTimelineSatisfactionSurveyResponseEventContent: React.FC<
	IEntityTimelineEventBaseProps<Api.ISatisfactionSurveyResponseEvent, Api.SatisfactionSurveyResponseEventViewModel>
> = ({ event }) => {
	const [expanded, setExpanded] = React.useState<boolean>(false);
	React.useEffect(() => {
		setExpanded(false);
	}, [event.response.feedback]);

	const feedback = React.useMemo<{
		expanded: string;
		collapsed?: string;
		// @ts-ignore
	}>(() => {
		const words = event.response.feedback?.split(' ');
		// @ts-ignore
		if (words?.length > 100) {
			return {
				// @ts-ignore
				collapsed: words.slice(0, 100).join(' '),
				expanded: event.response.feedback,
			};
		}
		return { expanded: event.response.feedback };
	}, [event.response.feedback]);

	if (!event.response.feedback) {
		return null;
	}

	const expand = () => setExpanded(true);

	return (
		<div className={css(styleSheet.satisfactionSurveyResponseEventContent)}>
			<div className={css(styleSheet.satisfactionSurveyResponseEventRow)}>
				<div className={css(styleSheet.satisfactionSurveyResponseEventContentLabel)}>Feedback:</div>
				&nbsp;
				{!feedback.collapsed || expanded ? (
					<div className={css(baseStyleSheet.breakWord, styleSheet.satisfactionSurveyResponseEventFeedback)}>
						{`"${feedback.expanded}"`}
					</div>
				) : (
					<div className={css(baseStyleSheet.breakWord)}>
						{`"${feedback.collapsed}..."`}
						{!expanded && (
							<>
								&nbsp;
								<button className={css(baseStyleSheet.brandLink)} onClick={expand}>
									Read more
								</button>
							</>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export const EntityTimelineNoteEventContent = ({ note }: { note: Api.NoteEventViewModel }) => {
	return (
		<div className={css(styleSheet.eventNoteContainer)}>
			<RichContentDocumentEditor
				autoFocus={false}
				className='note-editor-body-editor'
				config={DefaultCompactEditorConfig}
				contentState={convertRawRichTextContentStateToRichContentEditorState(note.viewmodel.rawContentState)}
				readOnly={true}
			/>
			{note.viewmodel.attachments ? <Attachments attachments={note.note.attachments} /> : null}
		</div>
	);
};
