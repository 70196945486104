import * as React from 'react';
import { animated, config, useSpring } from 'react-spring-legacy';
import { SvgIcon } from '../../icons/SvgIcon';

const sentStyles = {
	config: config.wobbly,
	from: { opacity: 0, transform: 'translate3d(50px, 0, 0)' },
	to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
};

const receivedStyles = {
	config: config.wobbly,
	from: { opacity: 0, transform: 'translate3d(-50px, 0, 0)' },
	to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
};

const firstDelay = 1000;
const minDelayBetweenMsgs = 300;
const secondDelay = firstDelay + minDelayBetweenMsgs + Math.random() * 1000;
const thirdDelay = secondDelay + minDelayBetweenMsgs + Math.random() * 1000;

const msg1Path = {
	d: 'M108 14V14.3508L108.219 14.6247L111.719 19L108.219 23.3753L108 23.6492V24V26C108 27.6569 106.657 29 105 29H35C33.3431 29 32 27.6569 32 26V4C32 2.34315 33.3431 1 35 1H105C106.657 1 108 2.34315 108 4V14Z',
	fill: '#E8F3F9',
	stroke: '#00AAE8',
	strokeWidth: '2',
};

const msg1Rect = {
	fill: '#CAE0EC',
	height: '3.24138',
	rx: '1.62069',
	width: '35.75',
	x: '40.875',
	y: '14.3103',
};

const msg2Path = {
	d: 'M108 52V52.3508L108.219 52.6247L111.719 57L108.219 61.3753L108 61.6492V62V64C108 65.6569 106.657 67 105 67H35C33.3431 67 32 65.6569 32 64V42C32 40.3431 33.3431 39 35 39H105C106.657 39 108 40.3431 108 42V52Z',
	fill: '#E8F3F9',
	stroke: '#00AAE8',
	strokeWidth: '2',
};

const msg2Rect1 = {
	fill: '#CAE0EC',
	height: '3.24138',
	rx: '1.62069',
	width: '55.75',
	x: '40.875',
	y: '47.3103',
};

const msg2Rect2 = {
	fill: '#CAE0EC',
	height: '3.24138',
	rx: '1.62069',
	width: '35.75',
	x: '40.875',
	y: '56.3103',
};

const msg3Path = {
	d: 'M5 98V97.6492L4.78087 97.3753L1.28062 93L4.78087 88.6247L5 88.3508V88V80C5 78.3431 6.34315 77 8 77H58C59.6569 77 61 78.3431 61 80V102C61 103.657 59.6569 105 58 105H8C6.34314 105 5 103.657 5 102V98Z',
	fill: 'white',
	stroke: '#00AAE8',
	strokeWidth: '2',
};

const msg3Rect = {
	fill: '#CAE0EC',
	height: '3.24138',
	rx: '1.62069',
	width: '35.75',
	x: '13.875',
	y: '90.2068',
};

export const TextingGraphic = ({ animate, ...props }: React.SVGProps<SVGSVGElement> & { animate?: boolean }) => {
	const msg1styles = useSpring({ ...sentStyles, delay: firstDelay });
	const msg2styles = useSpring({ ...sentStyles, delay: secondDelay });
	const msg3styles = useSpring({ ...receivedStyles, delay: thirdDelay });

	const renderMessage1 = () => {
		return animate ? (
			<>
				<animated.path style={msg1styles as React.CSSProperties} {...msg1Path} />
				<animated.rect style={msg1styles as React.CSSProperties} {...msg1Rect} />
			</>
		) : (
			<>
				<path {...msg1Path} />
				<rect {...msg1Rect} />
			</>
		);
	};

	const renderMessage2 = () => {
		return animate ? (
			<>
				<animated.path style={msg2styles as React.CSSProperties} {...msg2Path} />
				<animated.rect style={msg2styles as React.CSSProperties} {...msg2Rect1} />
				<animated.rect style={msg2styles as React.CSSProperties} {...msg2Rect2} />
			</>
		) : (
			<>
				<path {...msg2Path} />
				<rect {...msg2Rect1} />
				<rect {...msg2Rect2} />
			</>
		);
	};

	const renderMessage3 = () => {
		return animate ? (
			<>
				<animated.path style={msg3styles as React.CSSProperties} {...msg3Path} />
				<animated.rect style={msg3styles as React.CSSProperties} {...msg3Rect} />
			</>
		) : (
			<>
				<path {...msg3Path} />
				<rect {...msg3Rect} />
			</>
		);
	};
	return (
		<SvgIcon height={106} width={113} {...props}>
			{renderMessage1()}
			{renderMessage2()}
			{renderMessage3()}
		</SvgIcon>
	);
};
