import { titles } from '../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	body: {
		marginTop: 26,
	},
	container: {
		textAlign: 'center',
	},
	message: {
		color: '#4a4a4a',
		fontSize: 14,
		lineHeight: '18px',
		marginTop: 15,
	},
	scheduleButton: {
		margin: '54px auto 0',
	},
	title: {
		color: titles,
		fontSize: 18,
	},
});
