import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const AddUserIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`add-user-icon ${className || ''}`} height={22} width={22}>
			<g fill='none' fillRule='evenodd' transform='translate(1)'>
				<g
					stroke={fillColor || '#00AAE8'}
					strokeLinecap='round'
					strokeLinejoin='round'
					strokeWidth='2'
					transform='translate(0 5)'
				>
					<path d='M13.5757576,15.372549 L13.5757576,13.6993464 C13.5757576,11.8511778 12.0562392,10.3529412 10.1818182,10.3529412 L3.39393939,10.3529412 C1.51951842,10.3529412 1.88401483e-16,11.8511778 0,13.6993464 L0,15.372549' />
					<ellipse cx='7.127' cy='3.346' rx='3.394' ry='3.346' />
				</g>
				<polygon
					fill={fillColor || '#00AAE8'}
					points='4.383 0 2.922 0 2.922 2.922 0 2.922 0 4.383 2.922 4.383 2.922 7.304 4.383 7.304 4.383 4.383 7.304 4.383 7.304 2.922 4.383 2.922'
					transform='translate(13.696)'
				/>
			</g>
		</SvgIcon>
	);
};
