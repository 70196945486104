import { brandPrimaryHover } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fill?: string;
}

const height = 20;
const width = 14;

export const TextMessageTail: React.FC<IProps> = ({ className, fill = brandPrimaryHover }) => {
	return (
		<SvgIcon className={`text-message-tail ${className}`} height={height} width={width}>
			<g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
				<path
					d='M7.02075195,0 C7.02075195,10.9721311 9.01437632,17.107392 13.0016251,18.4057827 C18.9824982,20.3533688 0,22.6527195 0,13.9586277 C0,8.1625665 2.34025065,3.5096906 7.02075195,0 Z'
					fill={fill}
				/>
			</g>
		</SvgIcon>
	);
};
