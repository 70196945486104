import { IAppToastMessage } from '../../../../viewmodels/AppViewModels';
import SuccessCheckIconUrl from '../../../assets/successCheck.svg';
import './styles.less';
import * as React from 'react';

interface IProps {
	className?: string;
	toastMessage: IAppToastMessage;
}

export const EmailSuccessToast: React.FC<IProps> = props => {
	return (
		<div className={`email-success-toast ${props.className || ''}`}>
			<img src={SuccessCheckIconUrl} className='email-success-toast-icon' />
			<span className='email-success-toast-text'>{props.toastMessage.message}</span>
		</div>
	);
};
