import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const BouncedEmailsGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`bounced-emails-graphic ${className || ''}`} height={123} width={88}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M71.72 4.24c.764 0 1.383-.62 1.383-1.386A1.384 1.384 0 1071.72 4.24z'
				fill='#E8F3F9'
				stroke='#00AAE8'
			/>
			<rect x='79' y='25.385' width='4.15' height='1.385' rx='.693' fill='#00AAE8' />
			<rect
				x='81.768'
				y='24.003'
				width='4.15'
				height='1.385'
				rx='.693'
				transform='rotate(90 81.768 24.003)'
				fill='#00AAE8'
			/>
			<rect x='83' y='11.385' width='4.15' height='1.385' rx='.693' fill='#00AAE8' />
			<rect
				x='85.768'
				y='10.003'
				width='4.15'
				height='1.385'
				rx='.693'
				transform='rotate(90 85.768 10.003)'
				fill='#00AAE8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M40 123c19.33 0 35-2.686 35-6s-15.67-6-35-6-35 2.686-35 6 15.67 6 35 6z'
				fill='#E8F3F9'
			/>
			<path
				d='M3.06 53.481l35-29.304a3 3 0 013.852 0l35 29.304a3 3 0 011.074 2.3v41.48a3 3 0 01-3 2.999h-70a3 3 0 01-3-3V55.781a3 3 0 011.074-2.3z'
				fill='#CAE0EC'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path fill='#fff' stroke='#00AAE8' strokeWidth='2' d='M2.986 56.26h74v28h-74z' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M1.986 56.26v40a4 4 0 004 4h53l-57-44z'
				fill='#E8F3F9'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M77.986 56.26v40a4 4 0 01-4 4h-53l57-44z'
				fill='#E8F3F9'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.153 77.26l-19.167 23 25-18.892-5.833-4.108zm26.667 0l19.166 23-25-18.892 5.833-4.108z'
				fill='#CAE0EC'
			/>
			<path
				d='M42.422 74.206l30.832 26.054H7.719l30.83-26.054a3 3 0 013.873 0z'
				fill='#fff'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M37.586 78.308a4 4 0 014.8 0l26.6 19.952h-58l26.6-19.952z'
				fill='#E8F3F9'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M39.5 65C56.897 65 71 50.897 71 33.5S56.897 2 39.5 2 8 16.103 8 33.5 22.103 65 39.5 65z'
				fill='#fff'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				d='M42.587 13.168l17.799 30.313c1.174 2-.268 4.519-2.587 4.519H22.2c-2.32 0-3.761-2.52-2.587-4.519l17.799-30.313c1.16-1.974 4.014-1.974 5.174 0z'
				fill='#fff'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M39.177 12.877a1 1 0 011.713 0l19.644 32.607A1 1 0 0159.677 47H20.324a1 1 0 01-.856-1.517l19.71-32.606z'
				fill='#FFDCC2'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M39 25h2l-.5 11h-1L39 25zm1 15a1 1 0 100-2 1 1 0 000 2z'
				fill='#00AAE8'
			/>
			<rect x='46' y='24' width='10' height='2' rx='1' fill='#E8F3F9' />
			<rect x='57' y='24' width='6' height='2' rx='1' fill='#E8F3F9' />
			<rect x='46' y='27' width='14' height='2' rx='1' fill='#E8F3F9' />
			<rect x='17' y='37' width='10' height='2' rx='1' fill='#E8F3F9' />
			<rect x='11' y='39' width='6' height='2' rx='1' fill='#E8F3F9' />
			<rect x='18' y='39' width='8' height='2' rx='1' fill='#E8F3F9' />
		</SvgIcon>
	);
};
