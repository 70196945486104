import { StyleSheet } from 'aphrodite';
import {
	background,
	borderColor,
	brandPrimaryText,
	brandSecondary,
	darkGrayFontColor,
	error,
	inputBorderColor,
	mention,
	navigation,
} from '../../../styles/colors';
import { CssSelectors, baseStyleSheet } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	recipientsField: {
		background,
		border: `1px solid ${inputBorderColor}`,
		borderRadius: 3,
		boxSizing: 'border-box',
		color: darkGrayFontColor,
		display: 'flex',
		flexWrap: 'wrap',
		fontSize: '0.75rem',
		gap: '0.5rem',
		padding: 10,
	},
	recipientsFieldAddCcButton: {
		alignSelf: 'flex-start',
		color: brandPrimaryText,
		display: 'inline-block',
		flexShrink: 0,
		fontSize: '0.875rem',
		lineHeight: '22px',
		padding: '5px 0',
	},
	recipientsFieldDropdown: {
		backgroundColor: 'white',
		boxShadow: '0 4px 6px -1px #0000001a,0 2px 4px -2px #0000001a',
		maxHeight: '20rem',
		overflow: 'auto',
		padding: 0,
		position: 'absolute',
		width: 'inherit',
		zIndex: 2,
	},
	recipientsFieldDropdownItem: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		gap: '0.5rem',
	},
	recipientsFieldDropdownItemAvatar: {
		height: '2rem',
		minWidth: '2rem',
		width: '2rem',
	},
	recipientsFieldDropdownItemDetails: {
		flex: 1,
	},
	recipientsFieldDropdownItemDetailsEmail: {
		color: navigation,
		fontSize: '0.75rem',

		letterSpacing: 0,
	},
	recipientsFieldDropdownItemDetailsName: {
		color: 'black',
		fontSize: '0.875rem',
	},
	recipientsFieldDropdownItemHighlighted: {
		backgroundColor: background,
	},
	recipientsFieldDropdownItemMore: {
		borderTop: `1px solid ${borderColor}`,
		color: 'black',
		fontSize: '0.875rem',
		padding: '0.5rem',
	},
	recipientsFieldDropdownItemSelected: {
		...baseStyleSheet.fontBold,
	},
	recipientsFieldErrorTokenDropdown: {
		display: 'inline-flex',
	},
	recipientsFieldErrorTokenDropdownAnchor: {
		display: 'flex',
	},
	recipientsFieldErrorTokenDropdownContent: {
		background: error,
		borderRadius: 3,
		boxShadow: '0 1px 2px 0 rgba(162,162,162,0.50)',
		boxSizing: 'border-box',
		color: '#fff',
		display: 'flex',
		flexGrow: 1,
		fontSize: '0.875rem',
		letterSpacing: 0,
		marginTop: 2,
		padding: '10px 20px',
		whiteSpace: 'nowrap',
	},
	recipientsFieldInput: {
		background: 'transparent',
		border: 'none',
		outline: 'none',
		width: '100%',
	},
	recipientsFieldInputContainer: {
		display: 'flex',
		flexGrow: 1,
		gap: '0.25rem',
	},
	recipientsFieldLabel: {
		flexShrink: 0,
		marginRight: 10,
		marginTop: 6,
	},
	recipientsFieldToken: {
		alignItems: 'center',
		border: `1px solid ${brandSecondary}`,
		borderRadius: 3,
		cursor: 'pointer',
		display: 'inline-flex',
		height: '1.5rem',
		letterSpacing: 0,
		minWidth: 60,
		padding: '0 0 0 0.25rem',
	},
	recipientsFieldTokenDivider: {
		background: brandSecondary,
		height: '100%',
		width: 1,
	},
	recipientsFieldTokenDividerError: {
		background: error,
	},
	recipientsFieldTokenDropdownContent: {
		marginLeft: -3,
		marginTop: 3,
		minWidth: '100%',
	},
	recipientsFieldTokenDropdownMenu: {
		background: '#fff',
		border: `1px solid ${borderColor}`,
		boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
		marginTop: 5,
		position: 'absolute',
		width: '100%',
		zIndex: -1,
	},
	recipientsFieldTokenDropdownMenuItem: {
		':hover': {
			backgroundColor: borderColor,
		},
		boxSizing: 'border-box',
		padding: 10,
		width: '100%',
	},
	recipientsFieldTokenDropdownTrigger: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		marginLeft: '-0.5rem',
		padding: '0.5rem',
	},
	recipientsFieldTokenError: {
		border: `1px solid ${error}`,
	},
	recipientsFieldTokenRemoveButton: {
		alignItems: 'center',
		display: 'flex',
		height: '100%',
		justifyContent: 'center',
		position: 'absolute',
		width: '100%',
	},
	recipientsFieldTokenRemoveButtonContainer: {
		borderLeft: `1px solid ${brandSecondary}`,
		height: '100%',
		position: 'relative',
		width: 20,
	},
	recipientsFieldTokens: {
		display: 'flex',
		flexGrow: 1,
		flexWrap: 'wrap',
		gap: '0.5rem',
		width: '100%',
	},
	recipientsFieldTokenSelected: {
		background: mention,
	},
	recipientsFieldTokenText: {
		alignItems: 'center',
		display: 'flex',
		flexGrow: 1,
		flexShrink: 0,
		...CssSelectors.allChildren(
			{
				marginRight: '0.25rem',
			},
			':first-child'
		),
	},
	root: {
		width: '100%',
	},
});
