import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import { inject } from 'mobx-react';
import * as React from 'react';
import { IModalContext, ModalChildComponentContextKey } from '../../../../models';
import { useEventLogging } from '../../../../models/Logging';
import { useEmailPreviewQuery } from '../../../../queries';
import { baseStyleSheet } from '../../../styles/styles';
import { DeprecatedCloseButton } from '../../DeprecatedCloseButton';
import { LoadingSpinner } from '../../LoadingSpinner';
import PlaceholdersCss from '../../richContent/RichContentDocumentEditor/placeholders.tinymce.css';
import { styleSheet } from './styles';

function _EmailPreview({
	emailContent,
	signatureTemplateId,
	parentModal,
	title,
}: {
	emailContent: Api.IRawRichTextContentState;
	signatureTemplateId?: string;
	title?: string;
} & IModalContext) {
	const { logInput } = useEventLogging('EmailPreview');
	const emailPreviewQuery = useEmailPreviewQuery({
		content: emailContent,
		enabled: !!emailContent,
		signatureTemplateId,
	});
	const iframeRef = React.useRef<HTMLIFrameElement>(null);
	const onRequestClose = () => {
		parentModal?.onRequestClose(null, true);
		logInput('Close', 'Click');
	};

	React.useEffect(() => {
		const iframeEl = iframeRef.current;
		if (emailPreviewQuery.data && iframeEl && iframeEl.contentDocument && iframeEl.contentWindow) {
			iframeEl.contentDocument.write(
				`<html><head><link href="https://fonts.googleapis.com/css?family=Open+Sans" rel="stylesheet"/><link rel="stylesheet" type="text/css" href="${PlaceholdersCss}"/></head><body style="padding:0;margin:0;font-family:'OpenSans-Regular',sans-serif;">${emailPreviewQuery.data}</body></html>`
			);
			iframeEl.contentWindow.document.close();
		}
	}, [emailPreviewQuery.data]);

	if (!emailContent) {
		return null;
	}
	return (
		<div className={css(styleSheet.emailPreview)}>
			<div className={css(styleSheet.emailPreviewHeader)}>
				<DeprecatedCloseButton onClick={onRequestClose} />
			</div>
			<div className={css(styleSheet.emailPreviewTitleHeader, baseStyleSheet.truncateText)}>{title}</div>
			<div className={css(styleSheet.emailPreviewBody)}>
				{emailPreviewQuery.data ? (
					<iframe
						className={css(styleSheet.emailPreviewBodyIframe)}
						frameBorder={0}
						ref={iframeRef}
						sandbox='allow-same-origin'
					/>
				) : (
					<LoadingSpinner type='large' className='absolute-center' />
				)}
			</div>
			<div className={css(styleSheet.emailPreviewFooter)}>
				<button className={css(baseStyleSheet.ctaButton)} onClick={onRequestClose}>
					<span>Okay</span>
				</button>
			</div>
		</div>
	);
}

export const EmailPreview = inject(ModalChildComponentContextKey)(_EmailPreview);
