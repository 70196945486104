import { header, nameCircles } from '../../../styles/colors';
import { BrowserMediaQueries, CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		'::-webkit-scrollbar': {
			background: '#f9f9f9',
			width: 8,
		},
		'::-webkit-scrollbar-thumb': {
			backgroundColor: '#D8D8D8',
			borderRadius: 10,
			padding: '0 2px',
		},
		marginRight: 4,
		overflow: 'auto',
		position: 'relative',
		...CssSelectors.allChildren(
			{
				marginBottom: 0,
			},
			'.message:last-child'
		),
	},
	dateSeparator: {
		':before': {
			background: nameCircles,
			content: "' '",
			height: 1,
			position: 'absolute',
			top: 12,
			width: '100%',
		},
		marginBottom: 20,
		position: 'relative',
		...CssSelectors.allChildren(
			{
				background: '#f9f9f9',
				color: nameCircles,
				fontSize: '12px',
				marginLeft: 20,
				padding: '0 12px',
				position: 'relative',
				zIndex: 0,
			},
			'span'
		),
		[BrowserMediaQueries.IE11]: {
			transform: 'rotate(180deg)',
		},
	},
	noMessages: {
		color: header,
		paddingTop: 30,
		[BrowserMediaQueries.IE11]: {
			transform: 'rotate(180deg)',
		},
	},
	otherPhoneNumbers: {
		margin: '10px auto 0',
		maxWidth: 300,
		textAlign: 'center',
		...CssSelectors.allChildren(
			{
				color: header,
			},
			'span'
		),
	},
});
