import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { styleSheet } from './styles';

interface ILazyLoaderProps {
	children?: React.ReactElement<any>;
	loadingContainerStyles?: StyleDeclarationValue[];
}
export const LazyLoader: React.FC<ILazyLoaderProps> = ({ children, loadingContainerStyles = [], ...restProps }) => {
	return (
		<React.Suspense
			fallback={
				<div className={css(styleSheet.loadingSpinnerContainer, ...loadingContainerStyles)}>
					<LoadingSpinner type='large' />
				</div>
			}
		>
			{children ? React.cloneElement(children, restProps) : null}
		</React.Suspense>
	);
};
