import { titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

const optionWidth = 300;

export const styleSheet = StyleSheet.create({
	body: {
		alignItems: 'flex-start',
		display: 'flex',
		justifyContent: 'center',
		marginTop: 30,
		...CssSelectors.allChildren(
			{
				marginLeft: 86,
			},
			':not(:first-child)'
		),
	},
	configureSend: {
		width: optionWidth,
	},
	container: {
		position: 'relative',
	},
	header: {
		color: titles,
		fontSize: 18,
		textAlign: 'center',
	},
	overlay: {
		background: '#fff',
	},
	overlayBackButton: {
		fontSize: 14,
	},
	sendNow: {
		textAlign: 'center',
		width: optionWidth,
	},
	sendNowBody: {
		marginTop: 26,
	},
	sendNowButton: {
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: 54,
	},
	sendNowIcon: {},
	sendNowMessage: {
		color: '#4a4a4a',
		fontSize: 14,
		lineHeight: '18px',
		marginTop: 15,
	},
	sendNowMessageWarning: {
		background: '#FFF9DA',
		border: '1px solid #F3CE0D',
		borderRadius: 20,
		boxSizing: 'border-box',
		padding: '8px 20px',
	},
	sendNowTitle: {
		color: titles,
		fontSize: 18,
	},
});
