import { titles } from '../../../styles/colors';
import { BreakPoints, Layouts } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	actionCol: {
		flexShrink: 0,
		width: 60,
	},
	container: {
		background: '#E8F3F9',
		boxSizing: 'border-box',
		padding: '28px 20px',
	},
	header: {
		color: titles,
		fontSize: 16,
	},
	nameCol: {
		flexGrow: 1,
		minWidth: 140,
	},
	statusCol: {
		width: 80,
	},
	stepsCol: {
		width: 130,
	},
	tableBodyRow: {
		alignItems: 'center',
		boxSizing: 'border-box',
		color: titles,
		display: 'flex',
		fontSize: 14,
		padding: '8px 0',
	},
	tableBodyRowLoading: {
		padding: '8px 0',
	},
	tableHeader: {
		color: '#AAAAAA',
		marginTop: 20,
	},
	tableRow: {
		...Layouts.horizontalStack(30),
		[BreakPoints.tabletWidth]: {
			...Layouts.horizontalStack(20),
		},
	},
});
