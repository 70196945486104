import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { SentEmailEventViewModel } from '../../../../extViewmodels';
import { convertRawRichTextContentStateToRichContentEditorState } from '../../../../models/UiUtils';
import { useLambda } from '../../../../models/hooks/useLambda';
import { Attachments } from '../../Attachments';
import { Modal } from '../../Modal';
import { DefaultCompactEditorConfig } from '../../notes/NoteEditor';
import { RichContentDocumentEditor } from '../../richContent/RichContentDocumentEditor';
import { styleSheet } from './styles';

interface IProps {
	className?: string;
	styles?: StyleDeclarationValue[];
	event?: SentEmailEventViewModel;
}

export const EntityModalContent: React.FC<IProps> = props => {
	const { className, styles = [], event } = props;
	const [isOpen, , setOpen] = useLambda(false);

	return (
		<div className={`${css(styleSheet.container, ...styles)} ${className || ''}`}>
			<div className={css(styleSheet.previewContainer)}>
				<div>
					{/* @ts-ignore */}
					<p>{event.note.preview}</p>
					{/* @ts-ignore */}
					{event.note.attachments ? (
						// @ts-ignore
						<Attachments attachments={event.note.attachments} className={css(styleSheet.attachments)} />
					) : null}
				</div>
				<button className={css(styleSheet.button)} onClick={setOpen(true)}>
					Show more
				</button>
			</div>
			<Modal
				className={css(styleSheet.modalClassName)}
				isOpen={isOpen}
				onRequestClose={setOpen(false)}
				useDefaultHeader={true}
			>
				<div className={css(styleSheet.contentContainer)}>
					<RichContentDocumentEditor
						autoFocus={false}
						className='note-editor-body-editor'
						config={DefaultCompactEditorConfig}
						// @ts-ignore
						contentState={convertRawRichTextContentStateToRichContentEditorState(event.note?.rawContentState)}
						readOnly={true}
					/>
					{/* @ts-ignore */}
					{/* @ts-ignore */}
					{event.note.attachments && <Attachments attachments={event.note.attachments} />}
				</div>
			</Modal>
		</div>
	);
};
