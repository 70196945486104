import { getDisplayName } from '../../../models/UiUtils';
import { styleSheet } from './styles';
import * as Api from '@ViewModels';
import { css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';

interface IProps {
	scheduledMeeting: Api.IScheduledMeeting;
}

export const TimelineScheduledMeeting: React.FC<IProps> = ({ scheduledMeeting }) => {
	if (!scheduledMeeting) {
		return null;
	}

	const { participants, startDate } = scheduledMeeting;

	// @ts-ignore
	const contact = participants.filter(
		c => c._type === 'ContactParticipant' && !c.isOptional
	)?.[0] as Api.IContactParticipant;

	// @ts-ignore
	const guests = participants.filter(c => c._type === 'ContactParticipant' && c.isOptional);

	const meetingDate = moment(startDate).format('dddd, MMMM Do, YYYY');

	// @ts-ignore
	const guestNames = guests?.map(g => getDisplayName(g?.user))?.join(', ');

	const title = scheduledMeeting?.title;
	const name = getDisplayName(contact);
	const phoneNumber = contact?.phoneNumber;
	const emailAddress = contact?.emailAddress;

	return (
		<div className={css(styleSheet.container)}>
			{title && <div className={css(styleSheet.itemTitle)}>{title}</div>}
			{name && <div className={css(styleSheet.itemText)}>Name: {name}</div>}
			{phoneNumber && <div className={css(styleSheet.itemText)}>Phone Number: {phoneNumber}</div>}
			{emailAddress && <div className={css(styleSheet.itemText)}>Email: {emailAddress}</div>}
			{guestNames && <div className={css(styleSheet.itemText)}>Meeting Guests: {guestNames}</div>}
			{startDate && (
				<div className={css(styleSheet.itemText)}>
					Meeting Date: <span className={css(styleSheet.dateContainer)}>{meetingDate}</span>
				</div>
			)}
		</div>
	);
};
