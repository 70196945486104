import { titles } from '../../../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	alignCenter: {
		alignSelf: 'center',
	},
	bold: {
		color: titles,
		fontSize: 16,
	},
	container: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-around',
		margin: 30,
	},
	text: {
		alignSelf: 'flex-start',
		color: '#4a4a4a',
		fontSize: 14,
		marginLeft: 40,
		maxWidth: 350,
	},
});
