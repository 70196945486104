import { brandPrimaryHover, nameCircles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	closeButton: {
		marginLeft: -20,
		paddingBottom: 8,
	},
	container: {},
	fileArea: {
		display: 'flex',
		padding: 8,
	},
	fileContainer: {
		alignItems: 'center',
		background: '#EDEDED',
		boxSizing: 'border-box',
		color: brandPrimaryHover,
		display: 'inline-flex',
		fontSize: 12,
		marginRight: 4,
		padding: '4px 10px',
	},
	image: {
		borderRadius: 8,
		width: '93%',
	},
	imageArea: {
		display: 'flex',
		flex: 1,
		height: '100%',
		maxWidth: 240,
		padding: 8,
	},
	imageAreaContainer: {
		alignItems: 'flex-end',
		background: 'white',
		border: `1px solid ${nameCircles}`,
		borderBottom: 0,
		borderBottomLeftRadius: 0,
		borderBottomRightRadius: 0,
		borderTopLeftRadius: 3,
		borderTopRightRadius: 3,
		display: 'flex',
		...CssSelectors.allChildren(
			{
				marginLeft: -20,
			},
			':not(:first-child)'
		),
	},
	messageInput: {
		fontSize: '16px',
		paddingRight: 8,
	},
	messageInputContainer: {
		background: '#fff',
	},
	previewContainer: {
		display: 'flex',
		height: '100%',
		padding: 2,
	},
	sendMessageBtn: {
		':focus-visible': {
			outline: `1px dashed ${brandPrimaryHover}`,
			outlineOffset: 2,
			...CssSelectors.allChildren(
				{
					fill: brandPrimaryHover,
				},
				'svg > path'
			),
		},
		padding: 0,
	},
	sizeWarning: {
		textAlign: 'center',
	},
	textArea: {
		':focus-visible': {
			border: 'none',
			outline: 'none',
		},
		border: 'none',
		overflowY: 'hidden',
		resize: 'none',
		width: '95%',
		wordWrap: 'break-word',
	},
	textAreaContainer: {
		background: 'white',
		border: `1px solid ${nameCircles}`,
		borderRadius: 3,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '10px',
	},
	textAreaWithImages: {
		borderTop: `1px solid ${nameCircles}`,
		borderTopLeftRadius: 0,
		borderTopRightRadius: 0,
		paddingTop: 8,
	},
	toolbar: {
		alignItems: 'flex-end',
		paddingBottom: 5,
	},
	toolbarButtonsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		minWidth: 40,
	},
	transparentBtn: {
		background: 'none',
		border: 'none',
		outlone: 'none',
	},
});
