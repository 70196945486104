import { StyleSheet } from 'aphrodite';
import { borderColor, brandPrimary, titles } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';

export const styleSheet = StyleSheet.create({
	attachments: {
		paddingBottom: '10px',
	},
	button: {
		alignSelf: 'flex-end',
		color: brandPrimary,
		cursor: 'pointer',
		fontSize: 14,
		paddingBottom: '10px',
	},
	container: {
		borderBottom: `1px solid ${borderColor}`,
	},
	contentContainer: {},
	lessButton: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'flex-end',
		margin: '0 0 8px auto',
	},
	modalClassName: {
		...CssSelectors.allDescendants(
			{
				maxHeight: '90vh',
				overflowY: 'auto',
				width: 620,
			},
			'.modal-overlay-content'
		),
	},
	previewContainer: {
		alignItems: 'center',
		color: titles,
		display: 'flex',
		fontSize: 14,
		justifyContent: 'space-between',
	},
});
