import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { KeyDateKind, ScheduleCriteria } from '@ViewModels';
import { StyleDeclarationValue, css } from 'aphrodite';
import moment from 'moment';
import * as React from 'react';
import { baseStyleSheet } from '../../../styles/styles';
import { LoadingSpinner } from '../../LoadingSpinner';
import { ScheduleSendIcon } from '../../svgs/icons/ScheduleSendIcon';
import { styleSheet } from './styles';

interface IProps extends IEventLoggingComponentProps {
	className?: string;
	header?: string;
	isSending?: boolean;
	keyDateKind?: KeyDateKind;
	message?: string;
	onShowOmitConfirmation?(): void;
	onScheduleClicked?: (criteria: ScheduleCriteria, startDate: Date) => Promise<void> | void;
	styles?: StyleDeclarationValue[];
}

const KeyDateSendOptionsSFC: React.FC<IProps> = props => {
	const {
		className,
		styles,
		keyDateKind,
		isSending,
		onShowOmitConfirmation,
		onScheduleClicked,
		logInput,
		message,
		header,
	} = props;
	const [loading, setLoading] = React.useState<boolean>(false);

	const schedule = async () => {
		if (onShowOmitConfirmation) {
			onShowOmitConfirmation();
		} else {
			// we only care about the time of the day portion
			setLoading(true);
			const date = moment().utc().startOf('day').add(9, 'hours').toDate();
			// @ts-ignore
			logInput('Schedule', 'Click');
			await onScheduleClicked?.(ScheduleCriteria.OnDayOf, date);
			setLoading(false);
		}
	};

	return (
		<div className={`${css(styleSheet.container, ...(styles || []))} key-date-send-options ${className || ''}`}>
			<ScheduleSendIcon />
			<div className={css(styleSheet.body)}>
				<div className={css(styleSheet.title)}>
					<div>{header || `Schedule Send for ${keyDateKind}`}</div>
				</div>
				<div className={css(styleSheet.message)}>
					{message ||
						`Since we send these emails individually, we are able to send these emails on the actual day of the recipient’s ${keyDateKind}.`}
				</div>
				{loading ? (
					<LoadingSpinner type='small' />
				) : (
					<button
						className={css(baseStyleSheet.ctaButton, styleSheet.scheduleButton)}
						disabled={isSending}
						onClick={schedule}
					>
						<span>Schedule it</span>
					</button>
				)}
			</div>
		</div>
	);
};

export const KeyDateSendOptions = withEventLogging(KeyDateSendOptionsSFC, 'KeyDateSendOptions');
