import * as Api from '@ViewModels';
import * as React from 'react';
import { removeConversationMessages, useConversationMessagesFilterQuery } from '../../../../queries';
import { useDocumentVisibility } from '../../../containers/hooks';

const queryType = 'MissingTextMessages';

export function useMissedTextMessages({
	conversation,
	onError,
}: {
	conversation: Api.ConversationViewModel;
	onError?: (error: Api.IOperationResultNoValue) => void;
}) {
	const onErrorRef = React.useRef(onError);
	onErrorRef.current = onError;
	const conversationRef = React.useRef(conversation);
	conversationRef.current = conversation;

	const isVisible = useDocumentVisibility();
	const query = useConversationMessagesFilterQuery({
		conversationId: conversation?.id,
		enabled: Boolean(conversation?.id && !conversation?.isBusy && isVisible),
		refetchOnWindowFocus: false, // we are going to manually handle this to avoid refetching more data than we need on focus
		type: queryType,
	});

	React.useEffect(() => {
		const lastKnownTextMessage = conversationRef.current?.textMessages?.getByIndex(0) || undefined;
		if (!isVisible && conversationRef.current?.id) {
			removeConversationMessages(conversationRef.current.id, queryType);
		} else if (isVisible && conversationRef.current?.id && lastKnownTextMessage?.id && !query.isFetching) {
			const pageIndexForMatchingMessage = query.data?.pages?.findIndex(
				page => page.values.findIndex(message => message.id === lastKnownTextMessage.id) > -1
			);
			if (
				(pageIndexForMatchingMessage === undefined || pageIndexForMatchingMessage < 0) &&
				query.hasNextPage &&
				!query.error
			) {
				(async () => {
					try {
						await query.fetchNextPage();
					} catch (error) {
						onErrorRef.current?.(error);
					}
				})();
			} else {
				let missing: Api.ITextMessage[] = [];
				let done = false;
				query.data?.pages.slice(0, pageIndexForMatchingMessage + 1).forEach(page => {
					if (!done) {
						const index = page.values.findIndex(x => x.id === lastKnownTextMessage.id);
						missing = missing.concat(index < 0 ? page.values : page.values.slice(0, index));
						done = index >= 0;
					}
				});
				if (missing.length) {
					conversationRef.current.textMessages.addAll(
						missing.sort(Api.ConversationViewModel.defaultSort).reverse(),
						'unshift'
					);
				}
			}
		}
	}, [isVisible, query]);
}
