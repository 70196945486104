import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		width: 420,
	},
	errorMessage: {
		marginBottom: 20,
	},
	unlink: {
		':hover': {
			textDecoration: 'none',
		},
		display: 'inline-block',
		margin: `30px auto 0 auto`,
		textDecoration: 'none',
	},
});
