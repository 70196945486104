import { header, mention } from '../../styles/colors';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		padding: '10px 5px',
	},
	dateContainer: {
		background: mention,
		borderRadius: 12,
		color: '#000',
		display: 'inline-block',
		padding: '2px 10px',
	},
	itemText: {
		color: header,
		fontSize: 13,
		marginTop: 5,
	},
	itemTitle: {
		fontSize: 13,
		marginTop: 5,
	},
});
