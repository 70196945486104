import { StyleSheet } from 'aphrodite';
import { brandPrimaryHover, emailActivityTintColor, grayIconFill, header, titles } from '../../../styles/colors';
import { CssSelectors, truncateTextStyle } from '../../../styles/styles';

const LoadingMarginBottom = 100;

export const styleSheet = StyleSheet.create({
	actionItemRightAccessory: {
		color: brandPrimaryHover,
		cursor: 'pointer',
	},
	container: {
		marginTop: -10,
		paddingBottom: LoadingMarginBottom,
		position: 'relative',
	},
	dollarSignIcon: {
		marginLeft: 2,
	},
	eventAccessory: {},
	eventBodyContainer: {
		flexGrow: 1,
		marginLeft: 16,
	},
	eventContainer: {
		display: 'flex',
		marginBottom: 12,
		marginLeft: 28,
	},
	eventContent: {},
	eventDate: {
		marginLeft: 78,
	},
	eventDateContainer: {
		marginTop: 15,
	},
	eventDateFillLine: {
		background: grayIconFill,
		height: 16,
		margin: '3px 111px 7px',
		width: 2,
	},
	eventFillLine: {
		background: grayIconFill,
		height: 'calc(100% - 29px)',
		margin: '0 auto',
		width: 2,
	},
	eventIcon: {
		padding: '1px 0 4px',
	},
	eventIconContainer: {
		minWidth: 18,
	},
	eventNoteContainer: {
		...CssSelectors.allChildren(
			{
				marginTop: '0.5rem',
			},
			':not(:first-child)'
		),
		paddingBottom: '0.5rem',
	},
	eventTime: {
		color: grayIconFill,
		fontSize: 14,
		minWidth: 75,
	},
	eventTitle: {
		alignItems: 'center',
		color: header,
		display: 'flex',
		flex: 1,
		fontSize: 12,
		marginTop: 1,
		...truncateTextStyle,
	},
	eventTitleContainer: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'space-between',
	},
	eventTitleSurveyName: {
		color: titles,
		maxWidth: '25%',
	},
	eventTitleSurveyRating: {
		color: titles,
	},
	item: {
		border: 'none',
		padding: '30px 0px 30px 25px',
		...CssSelectors.allDescendants(
			{
				overflowX: 'auto',
			},
			'.rich-content-document-editor *[class^="readOnlyContent"]'
		),
	},
	loading: {
		marginTop: 30,
	},
	mentionField: {
		':nth-child(1n).note-editor-mention-field': {
			borderBottom: 'none',
			borderTop: 'none',
		},
	},
	moreMenu: {
		marginLeft: 10,
	},
	noteEditor: {
		':nth-child(1n).note-editor-body-editor': {
			minHeight: 0,
		},
	},
	noteEditorBody: {
		marginTop: 14,
	},
	popOver: {},
	popOverContent: {
		fontSize: 11,
		padding: '0 16px',
	},
	popOverContentWrap: {
		transform: 'translateX(-20%)',
	},
	popOverIconWrap: {
		alignItems: 'center',
		color: brandPrimaryHover,
		display: 'flex',
		fontSize: 11,
	},
	questionMarkIcon: {
		marginRight: 5,
	},
	satisfactionSurveyResponseEventContent: {},
	satisfactionSurveyResponseEventContentLabel: {
		color: '#858585',
	},
	satisfactionSurveyResponseEventFeedback: {
		whiteSpace: 'pre-wrap',
	},
	satisfactionSurveyResponseEventIcon: {
		height: 'auto',
		width: 18,
	},
	satisfactionSurveyResponseEventRow: {
		alignItems: 'flex-start',
		color: titles,
		display: 'flex',
		fontSize: 14,
	},
	sentOutSideOfLev: {
		background: '#E8F3F9',
		border: '1px solid #CAE0EC',
		color: '#4A4A4A',
		flexShrink: 0,
		fontSize: 12,
		padding: '3px 10px',
	},
	textingIcon: {
		marginLeft: 10,
	},
	textingIconSvg: {
		height: 16,
		width: 19,
	},
	viewedOn: {
		alignItems: 'center',
		background: '#f2f2f2',
		borderRadius: 5,
		color: header,
		display: 'flex',
		fontSize: 12,
		padding: 5,
		...CssSelectors.allChildren({
			background: emailActivityTintColor,
			borderRadius: '50%',
			display: 'inline-block',
			height: 18,
			marginRight: 8,
			position: 'relative',
			width: 18,
		}),
	},
});
