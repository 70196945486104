import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const CallIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`call-icon ${className || ''}`} height={16} width={16}>
			<path
				fill={fillColor || '#F9F9F9'}
				fillRule='evenodd'
				d='M15.285,11.89025 L12.707,9.29625 C12.317,8.90325 11.682,8.90325 11.291,9.29425 L9,11.58525 L4,6.58525 L6.294,4.29125 C6.684,3.90125 6.685,3.26825 6.295,2.87725 L3.715,0.29325 C3.324,-0.09775 2.691,-0.09775 2.3,0.29325 L0.004,2.58825 L0,2.58525 C0,9.76525 5.82,15.58525 13,15.58525 L15.283,13.30225 C15.673,12.91225 15.674,12.28125 15.285,11.89025'
			/>
		</SvgIcon>
	);
};
