import { destructive, titles } from '../../styles/colors';
import { baseStyleSheet } from '../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {
		background: '#FFE7EA',
		border: `2px solid ${destructive}`,
		color: titles,
		...baseStyleSheet.fontBold,
		fontSize: 14,
		height: 22,
		paddingLeft: 40,
		paddingTop: 4,
		position: 'fixed',
		width: '100%',
	},
});
