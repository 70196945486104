import { baseStyleSheet } from '../../../styles/styles';
import { ErrorMessageModal } from '../ErrorMessageModal';
import { styleSheet } from './styles';
import { IEventLoggingComponentProps, withEventLogging } from '@AppModels/Logging';
import { IOperationResultNoValue } from '@ViewModels';
import { css } from 'aphrodite';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { NavLink } from 'react-router-dom';

interface IProps extends IEventLoggingComponentProps {
	className?: string;
	onNavigateToReportingGroupEmail?(): void;
	onRequestClose?(): void;
	scheduleError?: IOperationResultNoValue;
}

class _ScheduledEmailErrorModal extends React.Component<IProps> {
	public render() {
		const { className, onRequestClose, scheduleError } = this.props;
		return (
			<ErrorMessageModal
				className={className}
				// @ts-ignore
				modalProps={{ isOpen: !!scheduleError, onRequestClose }}
				onRenderCta={this.onRenderCta}
				styles={[styleSheet.container]}
				title='Error'
			>
				{!!scheduleError?.systemMessage && (
					<div className={css(styleSheet.errorMessage)}>{scheduleError.systemMessage}</div>
				)}
				<div>
					Before trying to send again, please check Email Reporting to see if your email was successfully scheduled.
				</div>
			</ErrorMessageModal>
		);
	}

	private onRenderCta = (options?: { onClose?: () => void }) => {
		return (
			<NavLink
				className={css(baseStyleSheet.ctaButton, styleSheet.unlink)}
				onClick={this.onGroupEmailLinkClicked(options?.onClose)}
				to='/reporting/group-email'
			>
				<span>Go to Email Reporting</span>
			</NavLink>
		);
	};

	private onGroupEmailLinkClicked = (onClose?: () => void) => () => {
		const { logInput, onNavigateToReportingGroupEmail } = this.props;
		// @ts-ignore
		logInput('ReportingGroupEmailLink', 'Click');
		if (onClose) {
			onClose();
		}
		if (onNavigateToReportingGroupEmail) {
			onNavigateToReportingGroupEmail();
		}
	};
}

const ScheduledEmailErrorModalAsObserver = observer(_ScheduledEmailErrorModal);
const ScheduledEmailErrorModalWithContext = inject()(ScheduledEmailErrorModalAsObserver);
export const ScheduledEmailErrorModal = withEventLogging(
	ScheduledEmailErrorModalWithContext,
	'ScheduledEmailErrorModal'
);
