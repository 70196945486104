import { navigation } from '../../../../styles/colors';
import { SvgIcon } from '../SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
	fillColor?: string;
}

export const RoundRemoveIcon: React.FC<IProps> = props => {
	const { className, fillColor } = props;
	return (
		<SvgIcon className={`round-remove-icon ${className || ''}`} height={16} width={16}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M8 14.4C4.4712 14.4 1.6 11.5288 1.6 8C1.6 4.4712 4.4712 1.6 8 1.6C11.5288 1.6 14.4 4.4712 14.4 8C14.4 11.5288 11.5288 14.4 8 14.4M8 0C3.5888 0 0 3.5888 0 8C0 12.4112 3.5888 16 8 16C12.4112 16 16 12.4112 16 8C16 3.5888 12.4112 0 8 0'
				fill={fillColor || navigation}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M7.2 7.20001H4V8.80001H7.2H8.8H12V7.20001H8.8H7.2Z'
				fill={fillColor || navigation}
			/>
		</SvgIcon>
	);
};
