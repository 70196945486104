import { useState, useEffect } from 'react';

export const useDocumentVisibility = () => {
	const [documentState, setDocumentState] = useState(window.document.visibilityState);
	useEffect(() => {
		const onVisibilityChange = () => {
			const newDocumentState = window.document.visibilityState;
			setDocumentState(newDocumentState);
		};

		document.addEventListener('visibilitychange', onVisibilityChange);

		return () => {
			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, []);
	return documentState === 'visible';
};
