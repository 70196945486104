import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	modal: {
		...CssSelectors.allDescendants(
			{
				maxHeight: 'inherit',
				maxWidth: 'inherit',
			},
			'.modal-overlay-content'
		),
	},
});
