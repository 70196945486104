import { SvgIcon } from '../../icons/SvgIcon';
import * as React from 'react';

interface IProps {
	className?: string;
}

export const UnsubscribeEmailGraphic: React.FC<IProps> = props => {
	const { className } = props;
	return (
		<SvgIcon className={`unsubscribe-email-graphic ${className || ''}`} height={133} width={105}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M20.575 6.155c.87 0 1.575-.707 1.575-1.578a1.576 1.576 0 10-3.15 0c0 .871.705 1.578 1.575 1.578zm-16.5-.999c1.146 0 2.075-.93 2.075-2.078A2.076 2.076 0 004.075 1C2.929 1 2 1.93 2 3.078c0 1.148.929 2.078 2.075 2.078z'
				fill='#E8F3F9'
				stroke='#00AAE8'
			/>
			<rect y='19.385' width='4.15' height='1.385' rx='.693' fill='#00AAE8' />
			<rect
				x='2.768'
				y='18.003'
				width='4.15'
				height='1.385'
				rx='.693'
				transform='rotate(90 2.768 18.003)'
				fill='#00AAE8'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M61.5 133c20.158 0 36.5-2.686 36.5-6s-16.342-6-36.5-6-36.5 2.686-36.5 6 16.342 6 36.5 6z'
				fill='#E8F3F9'
			/>
			<path fill='#CAE0EC' stroke='#00AAE8' strokeWidth='2' d='M25 33h74v76H25z' />
			<path fill='#E8F3F9' stroke='#00AAE8' strokeWidth='2' d='M20 19h84v86H20z' />
			<rect x='46.5' y='67.5' width='32' height='1' rx='.5' fill='#D8D8D8' stroke='#00AAE8' />
			<rect x='46.5' y='87.5' width='41' height='1' rx='.5' fill='#D8D8D8' stroke='#00AAE8' />
			<rect x='46' y='73' width='17' height='6' rx='3' fill='#00AAE8' />
			<rect x='66' y='73' width='13' height='6' rx='3' fill='#00AAE8' />
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M62 60c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14z'
				fill='#fff'
				stroke='#00AAE8'
				strokeWidth='2'
			/>
			<path
				d='M67.667 51.49v-1.36c0-1.5-1.194-2.718-2.667-2.718h-5.333c-1.473 0-2.667 1.217-2.667 2.719v1.36'
				stroke='#00AAE8'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				fill='#FFF'
			/>
			<path
				clipRule='evenodd'
				d='M62.6 44.438c1.473 0 2.667-1.217 2.667-2.719 0-1.502-1.194-2.719-2.667-2.719-1.473 0-2.667 1.217-2.667 2.719 0 1.502 1.194 2.719 2.667 2.719z'
				stroke='#00AAE8'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
				fill='#FFF'
			/>
			<path fill='#F6CCAC' stroke='#00AAE8' d='M33 84h8v8h-8z' />
		</SvgIcon>
	);
};
