import { destructive, header, mention } from '../../../styles/colors';
import { CssSelectors } from '../../../styles/styles';
import { StyleSheet } from 'aphrodite';

export const styleSheet = StyleSheet.create({
	container: {},
	error: {
		color: destructive,
	},
	gray: {
		color: header,
		fontSize: '14px',
		paddingTop: 15,
	},
	header: {
		fontSize: '18px',
		padding: '20px 0 5px',
	},
	multiplePhoneNumbersSelect: {
		marginTop: 10,
		minWidth: 230,
	},
	multiplePhoneNumbersSelectContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		minHeight: '100%',
	},
	multiplePhoneNumbersSelectTitle: {
		...CssSelectors.allChildren(
			{
				':hover': {
					background: 'none',
				},
				padding: 0,
			},
			'.texting-phone-option'
		),
	},
	noValidPhoneOptionsIcon: {
		marginTop: 2,
	},
	phoneOption: {
		':hover': {
			background: mention,
			cursor: 'pointer',
		},
		color: '#000',
		padding: '10px 15px',
		...CssSelectors.allChildren(
			{
				color: header,
				display: 'inline-block',
				minWidth: 50,
				paddingRight: 10,
			},
			'span'
		),
	},
	selectContainer: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
	},
	textingGraphicContainer: {
		alignItems: 'center',
		background: mention,
		borderRadius: '50%',
		display: 'flex',
		height: 180,
		justifyContent: 'center',
		width: 180,
	},
	useThisNumberCta: {
		marginTop: 20,
	},
});
