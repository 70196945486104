import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps extends React.SVGProps<SVGSVGElement> {
	fillColor?: string;
}

export const DoubleArrowIcon: React.FC<IProps> = ({ fillColor, width = 18, height = 12, ...rest }: IProps) => {
	return (
		<SvgIcon height={height} width={width} {...rest}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M0 5h8.586L5.293 1.707 6.707.293 12.414 6l-5.707 5.707-1.414-1.414L8.586 7H0V5z'
				fill={fillColor || '#fff'}
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.293 1.707L11.707.293 17.414 6l-5.707 5.707-1.414-1.414L14.586 6l-4.293-4.293z'
				fill={fillColor || '#fff'}
			/>
		</SvgIcon>
	);
};
